export const CLAIM_FORM_BASE_ROUTE = '/claim'

export const makeClaimFormRoute = (page: string) =>
  `${CLAIM_FORM_BASE_ROUTE}/${page}`

export const ExternalRoutes = () => {
  const env = process.env.NEXT_PUBLIC_APP_ENV
  const allEnvs = {
    CLAIM_STATUS: 'https://uistatus.dol.state.nj.us',
    CERTIFY: 'https://lwdlba.state.nj.us/CertQueueMini/employerVerifyForm.htm',
    HOW_TO_CERTIFY:
      'https://nj.gov/labor/myunemployment/before/about/howtoapply/howtocertify.shtml',
    HOW_TO_REMAIN_ELIGIBLE:
      'https://nj.gov/labor/myunemployment/labor/myunemployment/help/faqs/eligibility.shtml#remain',
    ID_ME_VERIFY: 'https://uistatus.dol.state.nj.us/verify',
    ID_ME_DOCUMENT_TYPES:
      'https://help.id.me/hc/en-us/articles/360017833054-Primary-and-secondary-identification-documents',
    ID_ME_PROCESS:
      'https://help.id.me/hc/en-us/articles/1500005127662-Verifying-for-New-Jersey-DOL',
    TEMPORARY_DISABILITY_INSURANCE:
      'https://nj.gov/labor/myleavebenefits/worker/tdi/',
    EMPLOYER_DISABILITY_INSURANCE:
      'https://nj.gov/labor/myleavebenefits/employer/index.shtml?open=PrivatePlan',
    WORKERS_COMP:
      'https://www.nj.gov/labor/workerscompensation/injured-worker-protections/index.shtml',
    DOL_UNEMPLOYMENT_INFO:
      'https://www.dol.gov/general/topic/unemployment-insurance/',
    IN_PERSON_APPOINTMENT_LINK: 'https://telegov.njportal.com/njdolui',
    BENEFITS_CALCULATOR:
      'https://nj.gov/labor/myunemployment/before/about/calculator/index.shtml',
    WEEKLY_BENEFIT_RATE_FAQ:
      'https://nj.gov/labor/myunemployment/help/faqs/reducebenefits.shtml',
    ABOUT_BENEFITS_AND_TAXES:
      'https://nj.gov/labor/myunemployment/before/about/howtoapply/taxes.shtml',
    ABOUT_BENEFIT_PAYMENTS:
      'https://nj.gov/labor/myunemployment/before/about/payment/',
    JPAY: 'https://lwdlba.state.nj.us/CertQueueMini/employerVerifyForm.htm?_gl=1*1p327cp*_ga*MTYzNDMwOTc5OS4xNjgwNjE2MTc5*_ga_N8RFJ4LE4D*MTY5NzA0MTQ4OC4xMTkuMS4xNjk3MDQxNTIzLjAuMC4w*_ga_2F7W0D0NDJ*MTY5NzA0MTQ4OS42Ny4xLjE2OTcwNDE1MjMuMC4wLjA.&_ga=2.36456544.1502496578.1697033747-1634309799.1680616179',
    WHO_IS_ELIGIBLE:
      'https://www.nj.gov/labor/myunemployment/before/about/who/',
    APPEAL_PROCESS: 'https://www.nj.gov/labor/myunemployment/appeals/claimant/',
    DIRECT_DEPOSIT_AUTHORIZATION:
      'https://www.nj.gov/labor/myunemployment/assets/pdfs/BC-502.pdf',
    DIRECT_DEPOSIT_AUTHORIZATION_EMAIL: 'mailto: checkmaster@dol.nj.gov',
    EVETRECS: 'https://vetrecs.archives.gov/VeteranRequest/home.html',
    FEDERAL_CIRCUMSTANCE:
      'https://www.nj.gov/labor/myunemployment/before/circumstances/federal/',
    INELIGIBLE_REASONS:
      'https://www.nj.gov/labor/myunemployment/before/about/who/ineligible.shtml',
    EARNING_REQUIREMENTS:
      'https://www.nj.gov/labor/myunemployment/before/about/who/index.shtml#baseyear',
  }

  if (env === 'production') {
    return {
      ...allEnvs,
      UPDATE_PAYMENT_INFO:
        'https://uiclaim.dol.state.nj.us/njsuccess/html/updateDirectDepositHome.htm',
      TAX_DOCUMENTS:
        'https://uiclaim.dol.state.nj.us/njsuccess/html/web1099Home.htm',
      UPDATE_CONTACT_INFO:
        'https://uiclaim.dol.state.nj.us/njsuccess/html/updateAddressHome.htm',
      // AKA "SSI"
      LEGACY_APPLICATION: 'https://uiclaim.dol.state.nj.us/njsuccess',
    }
  } else {
    // Using securest for all non-production environments. SSI may have a dev environment
    // at stclaimproxy.dol.state.nj.us, however we currently point to only the SSO Test
    // environment, so pointing to other Test environments makes the experience consistent,
    // and avoids confusing authentication flows.
    return {
      ...allEnvs,
      UPDATE_PAYMENT_INFO:
        'https://securest.dol.state.nj.us/njsuccess/html/updateDirectDepositHome.htm',
      TAX_DOCUMENTS:
        'https://securest.dol.state.nj.us/njsuccess/html/web1099Home.htm',
      UPDATE_CONTACT_INFO:
        'https://securest.dol.state.nj.us/njsuccess/html/updateAddressHome.htm',
      // AKA "SSI"
      LEGACY_APPLICATION: 'https://securest.dol.state.nj.us/njsuccess',
    }
  }
}

export const Routes = {
  HOME: '/',
  SCREENER: '/screener',
  SCREENER_REDIRECT: '/screener-redirect',
  PRIVACY: '/privacy',
  MAINTENANCE: '/maintenance',
  ERROR_500: '/500',
  SIGNIN: '/signin',

  CERTIFY: ExternalRoutes().CERTIFY,
  CLAIM_STATUS: ExternalRoutes().CLAIM_STATUS,
  HOW_TO_CERTIFY: ExternalRoutes().HOW_TO_CERTIFY,
  HOW_TO_REMAIN_ELIGIBLE: ExternalRoutes().HOW_TO_REMAIN_ELIGIBLE,
  ID_ME_DOCUMENT_TYPES: ExternalRoutes().ID_ME_DOCUMENT_TYPES,
  ID_ME_PROCESS: ExternalRoutes().ID_ME_PROCESS,
  ID_ME_VERIFY: ExternalRoutes().ID_ME_VERIFY,
  LEGACY_APPLICATION: ExternalRoutes().LEGACY_APPLICATION,
  UPDATE_PAYMENT_INFO: ExternalRoutes().UPDATE_PAYMENT_INFO,
  TAX_DOCUMENTS: ExternalRoutes().TAX_DOCUMENTS,
  TEMPORARY_DISABILITY_INSURANCE:
    ExternalRoutes().TEMPORARY_DISABILITY_INSURANCE,
  EMPLOYER_DISABILITY_INSURANCE: ExternalRoutes().EMPLOYER_DISABILITY_INSURANCE,
  WORKERS_COMP_LINK: ExternalRoutes().WORKERS_COMP,
  UPDATE_CONTACT_INFO: ExternalRoutes().UPDATE_CONTACT_INFO,
  DOL_UNEMPLOYMENT_INFO: ExternalRoutes().DOL_UNEMPLOYMENT_INFO,
  BENEFITS_CALCULATOR_LINK: ExternalRoutes().BENEFITS_CALCULATOR,
  WEEKLY_BENEFIT_RATE_FAQ: ExternalRoutes().WEEKLY_BENEFIT_RATE_FAQ,
  ABOUT_BENEFITS_AND_TAXES: ExternalRoutes().ABOUT_BENEFITS_AND_TAXES,
  ABOUT_BENEFIT_PAYMENTS: ExternalRoutes().ABOUT_BENEFIT_PAYMENTS,
  JPAY_LINK: ExternalRoutes().JPAY,
  WHO_IS_ELIGIBLE: ExternalRoutes().WHO_IS_ELIGIBLE,
  APPEAL_PROCESS: ExternalRoutes().APPEAL_PROCESS,
  DIRECT_DEPOSIT_AUTHORIZATION: ExternalRoutes().DIRECT_DEPOSIT_AUTHORIZATION,
  DIRECT_DEPOSIT_AUTHORIZATION_EMAIL:
    ExternalRoutes().DIRECT_DEPOSIT_AUTHORIZATION_EMAIL,
  EVETRECS: ExternalRoutes().EVETRECS,
  FEDERAL_CIRCUMSTANCE: ExternalRoutes().FEDERAL_CIRCUMSTANCE,
  INELIGIBLE_REASONS: ExternalRoutes().INELIGIBLE_REASONS,
  EARNING_REQUIREMENTS: ExternalRoutes().EARNING_REQUIREMENTS,

  // Homepage
  IN_PERSON_APPOINTMENT_LINK: ExternalRoutes().IN_PERSON_APPOINTMENT_LINK,

  // Claim Form
  CLAIM: {
    START: makeClaimFormRoute('start'),
    PREQUAL: makeClaimFormRoute('prequal'),
    PERSONAL: makeClaimFormRoute('personal'),
    ADDRESS_VERIFICATION: makeClaimFormRoute('address-verification'),
    CONTACT: makeClaimFormRoute('contact'),
    DEMOGRAPHICS: makeClaimFormRoute('demographics'),
    IDENTITY: makeClaimFormRoute('identity'),
    RECENT_EMPLOYERS: makeClaimFormRoute('recent-employers'),
    EDIT_EMPLOYER: makeClaimFormRoute('edit-employer'),
    REVIEW_EMPLOYERS: makeClaimFormRoute('review-employers'),
    OCCUPATION: makeClaimFormRoute('occupation'),
    EDUCATION_VOCATIONAL_REHAB: makeClaimFormRoute('education-and-training'),
    UNION: makeClaimFormRoute('union'),
    DISABILITY: makeClaimFormRoute('disability'),
    PAYMENT: makeClaimFormRoute('payment'),
    REVIEW: makeClaimFormRoute('review'),
    SUCCESS: makeClaimFormRoute('success'),
  },
  REOPENER: {
    PAYMENT: '/reopener/[application_id]/payment',
    DISABILITY: '/reopener/[application_id]/disability',
    EDUCATION_VOCATIONAL_REHAB:
      '/reopener/[application_id]/education-and-training',
    EMPLOYMENT: '/reopener/[application_id]/employment',
    RECENT_EMPLOYERS: '/reopener/[application_id]/recent-employers',
    VERIFY_ADDRESS: '/reopener/[application_id]/verify-address',
    REVIEW: '/reopener/[application_id]/review',
    STEP_VERIFICATION: 'reopener/step-verification',
    START: 'reopener/start',
    SUCCESS: 'reopener/[application_id]/success',
  },
}
